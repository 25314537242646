import { styled } from '../../stitches.config';
import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function CookingIcon({ size }: IconBaseType) {
  return (
    <StyledSvg
      width="58"
      height="53"
      viewBox="0 0 58 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      size={size}
    >
      <g clip-path="url(#clip0_1811_250)">
        <StyledPath
          d="M22.3365 15.6994C22.0569 15.955 22.0414 16.3836 22.3054 16.6542C22.4452 16.7971 22.6316 16.8723 22.818 16.8723C22.9889 16.8723 23.1597 16.8121 23.2918 16.6918C25.5363 14.6618 24.4412 12.955 23.5636 11.5866C22.6782 10.2031 21.9792 9.10537 23.3694 7.33094C23.6024 7.03018 23.5403 6.60913 23.2374 6.38357C22.9345 6.158 22.4918 6.21815 22.2588 6.51139C20.2861 9.02267 21.4977 10.9174 22.3831 12.2933C23.2607 13.6693 23.75 14.4287 22.3443 15.6994H22.3365Z"
          fill="#181818"
        />
        <StyledPath d="M44 309 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16-11z" />
        <StyledPath
          d="M14.2213 15.6994C13.9417 15.955 13.9261 16.3836 14.1902 16.6542C14.33 16.7971 14.5164 16.8723 14.7028 16.8723C14.8736 16.8723 15.0445 16.8121 15.1765 16.6918C17.421 14.6618 16.326 12.955 15.4484 11.5866C14.563 10.2031 13.864 9.10537 15.2542 7.33094C15.4872 7.03018 15.4251 6.60913 15.1222 6.38357C14.8115 6.158 14.3766 6.21815 14.1436 6.51139C12.1709 9.02267 13.3825 10.9174 14.2679 12.2933C15.1455 13.6693 15.6348 14.4287 14.229 15.6994H14.2213Z"
          fill="#181818"
        />
        <StyledPath
          d="M47.5622 51.6465H10.3142C9.92589 51.6465 9.61523 51.9472 9.61523 52.3232C9.61523 52.6991 9.92589 52.9999 10.3142 52.9999H47.5622C47.9505 52.9999 48.2612 52.6991 48.2612 52.3232C48.2612 51.9472 47.9505 51.6465 47.5622 51.6465Z"
          fill="#181818"
        />
        <StyledPath
          d="M55.204 24.3079L51.5227 24.2853V20.6011C51.5227 20.2252 51.212 19.9244 50.8237 19.9244H44.5173C44.6493 18.8643 44.6105 17.8718 44.5794 16.9771C44.5328 15.8192 44.4862 14.8116 44.9134 14.368L57.8135 1.14246C58.0776 0.871787 58.062 0.443215 57.7824 0.187576C57.5028 -0.0680631 57.0601 -0.0530255 56.7961 0.217651L45.1464 12.1725C45.1464 12.1725 45.1464 12.1725 45.1386 12.1725L43.896 13.4432C43.4688 13.8868 42.4281 13.9019 41.2243 13.9244C38.8944 13.9695 35.7101 14.0297 32.8365 16.9771C32.2929 17.5334 31.4619 18.6387 30.7473 19.9169H7.03631C6.64799 19.9169 6.33733 20.2177 6.33733 20.5936V24.2778L2.65603 24.3004C1.57649 24.3079 0.636743 24.9094 0.217353 25.8793C-0.202036 26.8492 -0.000107785 27.9169 0.745473 28.6688L6.14317 34.1349C6.19754 34.1951 6.26743 34.2327 6.33733 34.2628V43.5936C6.33733 46.9846 9.18763 49.7365 12.6825 49.7365H45.1697C48.6724 49.7365 51.5149 46.9771 51.5149 43.5936V34.2628C51.5848 34.2327 51.6547 34.1876 51.7091 34.1349L57.1067 28.6688C57.8523 27.9094 58.0543 26.8417 57.6349 25.8793C57.2155 24.9169 56.2757 24.3079 55.1962 24.3004L55.204 24.3079ZM33.8539 17.9094C36.3237 15.3755 39.0575 15.3229 41.2554 15.2778C41.9466 15.2628 42.599 15.2552 43.1815 15.1575C43.1193 15.7289 43.1504 16.3605 43.1815 17.0297C43.2203 17.9094 43.2514 18.8943 43.1038 19.9169H32.355C32.9064 19.0297 33.4811 18.2928 33.8539 17.9019V17.9094ZM1.75511 27.744C1.39009 27.3755 1.29689 26.8793 1.50659 26.4131C1.70852 25.947 2.14344 25.6688 2.66379 25.6613L6.33733 25.6387V32.3831L1.75511 27.744ZM50.1247 43.6086C50.1247 46.2477 47.9035 48.3981 45.1774 48.3981H12.6903C9.96427 48.3981 7.74306 46.2477 7.74306 43.6086V21.2778H50.1325V43.6086H50.1247ZM56.1049 27.744L51.5227 32.3831V25.6387L55.1962 25.6613C55.7165 25.6613 56.1515 25.947 56.3534 26.4131C56.5553 26.8793 56.4621 27.3755 56.1049 27.744Z"
          fill="#181818"
        />
      </g>
      <defs>
        <clipPath id="clip0_1811_250">
          <rect width="58" height="53" fill="white" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default CookingIcon;
